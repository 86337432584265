import Bali from '../images/Bali.jpg'
import Europe from '../images/europe.jpg'
import Maldives from '../images/maldives-3.jpg'
import Srilanka from '../images/srilanka.jpg'
import Dubai from '../images/dubai.jpg'
import BangkoktThailand from '../images/bangkok-thailand.jpg'
import japan from '../images/japan.jpg'
import Rome from '../images/rome.jpg'
import London from '../images/london.jpg'
import Paris from '../images/paris.jpg'





const data = {
    cardData:[
        {
            id:1,
            img:Bali,
            title:'BALI',
            description:'Bali, an Indonesian paradise, offers serene beaches, vibrant coral reefs, and terraced rice fields. Its rich cultural heritage, highlighted by ornate temples and colorful festivals, welcomes travelers seeking relaxation and spiritual rejuvenation.',
        },
        {
            id:2,
            img:Europe,
            title:'EUROPE',
            description:'Europe, a tapestry of diverse cultures and landscapes, boasts historic cities like Rome, Paris, and London. From the majestic Alps to the sun-kissed Mediterranean shores, Europes charm lies in its artistic treasures, culinary delights, and architectural wonde.',
        },
        {
            id:3,
            img:Maldives,
            title:'MALDIVES',
            description:'The Maldives, a tropical haven, boasts pristine beaches, azure waters, and luxurious overwater bungalows. Its secluded islands are a haven for snorkeling and diving enthusiasts, showcasing vibrant marine life and unparalleled natural beauty.',

        },
        {
            id:4,
            img:Srilanka,
            title:'SRI LANKA',
            description:'Sri Lanka, teardrop-shaped off Indias southern coast, captivates with its lush tea plantations, ancient temples, and diverse wildlife. The cultural blend of colonial history and Buddhist heritage adds a unique allure to this island nation.',
        },
        {
            id:5,
            img:Dubai,
            title:'DUBAI',
            description:'Dubai, a futuristic metropolis in the desert, dazzles with its architectural marvels like the Burj Khalifa and luxurious shopping malls. Its a hub for luxury experiences, desert safaris, and a vibrant nightlife scene.',
        },
        {
            id:6,
            img:BangkoktThailand,
            title:'BANGKOK, THAILAND',
            description:'Bangkok, Thailand s bustling capital, merges ancient traditions with a modern lifestyle. Its vibrant street markets, ornate temples, and exquisite cuisine make it a magnet for travelers seeking a blend of history and urban excitement.',
        },
        {
            id:7,
            img:japan,
            title:'JAPAN',
            description:'Japan, a land of contrasts, harmonizes ancient traditions with cutting-edge technology. From serene temples and picturesque cherry blossoms to bullet trains and neon-lit cities like Tokyo, Japan offers a mesmerizing fusion of old and new.',
            
        },
        {
            id:8,
            img: London,
            title:'LONDON',
            description:'London, a cosmopolitan melting pot, exudes history, royalty, and modernity. From Buckingham Palace to the vibrant West End, London offers a blend of historic landmarks, diverse cultures, and a thriving arts scene.',

        },
        {
            id:9,
            img: Paris,
            title:'PARIS',
            description:'Paris, the City of Light, is synonymous with romance, art, and haute couture. Its iconic landmarks like the Eiffel Tower and Louvre Museum, along with charming cafes and chic boutiques, define its timeless elegance.',
        },
        {
            id:9,
            img: Rome,
            title:'ROME',
            description:'Rome, the Eternal City, showcases iconic ancient landmarks like the Colosseum and the Vatican. Its rich history, art, and cuisine lure visitors to wander through its cobblestone streets and experience its timeless grandeur.',

        },
        
        
        


    ]

}

export default data;