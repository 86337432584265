import React from "react";
import Russia from "../images/Russia.jpg";
import Newzland from '../images/Newzland.jpg'
import Japan from '../images/japan.jpg'

import Morocco from '../images/Morocco.jpg'



import "./blog.css";

const Blog = () => {
  return (
    <>
      <main>
        <div className="row content">
          <div style={{ paddingLeft: "0" }} className="col-md-4">
            <div className="article">
              <img
                src="https://myhoneymoon.fr/wp-content/uploads/2021/05/1-maldives-voyage-de-noces-avion-plage-hotel-Vue-de-haut.jpg"
                alt="Maldives"
              />
              <h2 className="location">
                <b>Maldives</b> <br />
                The hidden gems of the islands
              </h2>
              <p className="desc">
                the largest country globally, is a blend of rich history and
                cultural diversity, boasting iconic landmarks like the Kremlin
                and transcendent ballets in the opulent theaters of Moscow and
                St. Petersburg.{" "}
              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> August 12, 2017
                </span>
                <span className="comment">
                  <i className="far fa-comment-alt"></i> 1 comments
                </span>
              </div>
            </div>
            {/* <div className="article">
              <img src={Russia} alt="Russia" />
              <h2 className="location">
                <b>Russia</b> <br /> Things to do and places to visit
              </h2>
              <p className="desc">
                Steeped in rich history and culture, Russia's grandeur unfolds
                through its iconic architecture, world-renowned literature, and
                diverse landscapes.
              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> November 09, 2017
                </span>
              </div>
            </div> */}
          </div>
          <div style={{ padding: "0 7.5px" }} className="col-md-4">
            <div className="article quote">
              <p>
                “The most beautiful in the world is, of course, the world
                itself.” <br /> <span>- Wallace Stevens -</span>
              </p>
            </div>
            <div className="article">
              <img
                src="https://s1.travix.com/no/north-america-canada-lake-moraine-medium.jpg"
                alt="Canada"
              />
              <h2 className="location">
                <b>Canada</b> <br /> Road trip destinations
              </h2>
              <p className="desc">
                Nature's playground, Canada boasts majestic mountains, serene
                lakes, and expansive wilderness, inviting adventurers to explore
                its breathtaking beauty.
              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> April 23, 2017
                </span>
                <span className="comment">
                  <i className="far fa-comment-alt"></i> 4 comments
                </span>
              </div>
            </div>
          </div>


          <div style={{ paddingRight: "0" }} className="col-md-4">
            <div className="article">
              <img
                src="https://static.euronews.com/articles/stories/06/48/22/92/1440x810_cmsv2_31f9c339-7751-57b2-b3f1-da2551bffb47-6482292.jpg"
                alt="Greece"
              />
              <h2 className="location">
                <b>Greece</b> <br /> Travel tales and tips
              </h2>
              <p className="desc">
                An ancient cradle of civilization, Greece mesmerizes with its
                sun-drenched islands, archaeological treasures, and captivating
                Mediterranean charm.
              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> February 19, 2017
                </span>
                <span className="comment">
                  <i className="far fa-comment-alt"></i> 2 comments
                </span>
              </div>
            </div>
            <div className="article quote">
              <p>
                “Travel is never a matter of money but of courage.”
                <br /> <span>- Paolo Coelho -</span>
              </p>
            </div>
          </div>


          



        </div>


        <div className="row content">
          <div style={{ paddingLeft: "0" }} className="col-md-4">
            <div className="article">
              <img
                src={Japan}
                alt="Maldives"
              />
              <h2 className="location">
                <b>JAPANs</b> <br />
                Traditions in Tranquility
              </h2>
              <p className="desc">
              Experience the juxtaposition of ancient traditions and modern marvels, from serene temples and lush gardens to bustling cityscapes and high-tech innovation.

              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> August 12, 2017
                </span>
                <span className="comment">
                  <i className="far fa-comment-alt"></i> 1 comments
                </span>
              </div>
            </div>
            <div className="article">
              <img src={Russia} alt="Russia" />
              <h2 className="location">
                <b>MOROCCO</b> <br />Mystical Wonders and Souks
              </h2>
              <p className="desc">
              Embark on a sensory journey through vibrant markets, intricate architecture, and the Sahara's golden dunes, all while savoring delectable tagines and mint tea.

              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> November 09, 2017
                </span>
              </div>
            </div>
          </div>
          <div style={{ padding: "0 7.5px" }} className="col-md-4">
            <div className="article quote">
              <p>
              "Once a year, go someplace you've never been before."
                <br /> <span>- Dalai Lama - -</span>
              </p>
            </div>
            <div className="article">
              <img
                src={Newzland}
                alt="Canada"
              />
              <h2 className="location">
                <b>NEW ZEALAND</b> <br /> Adventure's Paradise
              </h2>
              <p className="desc">
              Discover the adrenaline rush amidst stunning landscapes, from rugged mountains and pristine beaches to thrilling outdoor adventures in the land of the Kiwis.

              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> April 23, 2017
                </span>
                <span className="comment">
                  <i className="far fa-comment-alt"></i> 4 comments
                </span>
              </div>
            </div>
          </div>


          <div style={{ paddingRight: "0" }} className="col-md-4">
            <div className="article">
              <img
                src={Morocco}
                alt="Greece"
              />
              <h2 className="location">
                <b>RUSSIA</b> <br /> Things to do and places to visit
              </h2>
              <p className="desc">
              Steeped in rich history and culture, Russia's grandeur unfolds through its iconic architecture, world-renowned literature, and diverse landscapes.
              </p>
              <div className="info">
                <span className="date">
                  <i className="far fa-calendar"></i> February 19, 2017
                </span>
                <span className="comment">
                  <i className="far fa-comment-alt"></i> 2 comments
                </span>
              </div>
            </div>
            <div className="article quote">
              <p>
              "Not all those who wander are lost."
                <br /> <span>- J.R.R. Tolkien - -</span>
              </p>
            </div>
          </div>


          



        </div>

      </main>
    </>
  );
};

export default Blog;
