import React from "react";
import "./aboutus.css";
// import mapAirplane from "./images/Airplane-Icon.mp4";
import { Slide, Zoom } from "react-awesome-reveal";
import mapAirplane from "../images/Airplane-Icon.mp4";
import Travela from "../images/travel.jpg";
import Travelb from "../images/travelb.jpg";

const Aboutus = () => {
  return (
    <div className="Aboutus__container container text-center">
      <div className="container download-card my-5">
        <div className="col-12 pt-3 ">
          <small className="text-uppercase" style={{ color: "#000000" }}>
            About us
          </small>
          <h1 className="h2 mb-4" style={{ fontWeight: 600 }}>
            About Our <span style={{ color: "#000000" }}>Company</span>
          </h1>
          <p className="text-secondary" style={{ lineHeight: 2 }}>
            Conquer Tour and Travels - Indian travel agency/tour operators for
            fulfill your complete travel requirement. Find travel in India, Joy
            tour and Travels offers you various choices at little amount backed
            by a good service. We offer you Indian Travel Package, International
            holidays, flight tickets, Honeymoon Package and several enormous
            bargains!
          </p>
          <center>
          <a
            href="#"
            style={{ backgroundColor: "#000000", width: "240px" }}
            className="download-card btn px-4 py-3 mt-4 text-white d-flex align-items-center justify-content-between"
          >
            <span className="download-pakage-btn">Download Pakages Card</span>
            <svg
              width="25px"
              fill="currentColor"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 489 489"
              style={{ enableBackground: "new 0 0 489 489" }}
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path
                    d="M329.2,327.2c-4.5,0-8.1,3.4-8.6,7.9c-3.9,38.6-36.5,68.7-76.2,68.7c-39.6,0-72.2-30.1-76.2-68.7
                    c-0.5-4.4-4.1-7.9-8.6-7.9h-104c-21.8,0-39.5,17.7-39.5,39.5v82.8c0,21.8,17.7,39.5,39.5,39.5h377.8c21.8,0,39.5-17.7,39.5-39.5
                    v-82.7c0-21.8-17.7-39.5-39.5-39.5H329.2V327.2z"
                  />
                  <path
                    d="M303.5,198.6l-30.9,30.9V28.1C272.6,12.6,260,0,244.5,0l0,0c-15.5,0-28.1,12.6-28.1,28.1v201.4l-30.9-30.9
                    c-9.5-9.5-24.7-11.9-35.9-4.4c-15.3,10.2-16.8,31.1-4.5,43.4l82.8,82.8c9.2,9.2,24.1,9.2,33.3,0l82.8-82.8
                    c12.3-12.3,10.8-33.2-4.5-43.4C328.2,186.6,313,189,303.5,198.6z"
                  />
                </g>
              </g>
            </svg>
          </a>
          </center>
        </div>
      </div>
      <div className="aboutus__title">
        <h1 className="rafi">KNOW ABOUT US</h1>
        <div className="aboutus__section">
          <div className=" col-12 col-sm-6 col-md-6 col-lg-6 aboutus__left-part">
            <div className="myelementSec">
              <Zoom triggerOnce={true}>
                <video
                  className="semicard31"
                  src={mapAirplane} // Corrected import and usage
                  alt="dff"
                  autoPlay
                  loop
                  muted
                  width="100%"
                  height="100%"
                ></video>
              </Zoom>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-6 col-lg-6 aboutus__right-part text-secondary" style={{ lineHeight: 1, textAlign: 'start' }}>
            <Slide direction="up">
              <div>
                Currently, we offer group tours spanning Pan India, Europe, and
                South East Asia. Additionally, we specialize in exclusive tours
                tailored for various demographics such as ladies, students,
                senior citizens, and newlyweds seeking exotic destinations. Our
                commitment involves ongoing research for new offerings, unique
                themes, and enhanced travel experiences to ensure top-notch
                service for our customers. Whether it's a corporate excursion, a
                romantic getaway, a pilgrimage, an adventure-filled journey, or
                a simple day trip, we feature an array of appealing tour options
                suitable for diverse preferences, needs, and budgets. Don't miss
                out—secure your holiday with us today!
              </div>
            </Slide>
          </div>
        </div>
      </div>
      <br></br>

      <div className="Font-align">
        <div className=" ourVision__title">
          <Slide direction="up">
            {" "}
            <h2>OUR VISION</h2>
          </Slide>
          <div className=" ourVision__Subtitle">
            <p>“Spreading Smiles and Happiness”</p>
          </div>
        </div>
        <br></br>
        <div className=" ourMission__title">
          <Slide direction="up">
            {" "}
            <h2>OUR MISSION</h2>{" "}
          </Slide>
          <div className=" ourVision__Subtitle">
            <p>
              “We are totally committed to quality travel experience with our
              value added services. We will always strive to uphold highest
              ethical standards and create new benchmarks in the tours and
              travel industry.”
            </p>
          </div>

          <br></br>

          <div className="ourMission__title">
            <Slide direction="up">
              {" "}
              <h2>Why Us</h2>{" "}
            </Slide>
            <div className=" ourVision__Subtitle">
              <p>
                <p>Certainly! Here's an expanded version: </p> <br></br>
                "Discover Unforgettable Journeys At our travel agency, we stand
                by a legacy built on trust and recommendation. Our travelers
                frequently embark on their adventures following enthusiastic
                referrals from fellow explorers. They come seeking more than
                just a mere itinerary; they seek experiences crafted with care
                and expertise. When you travel with us, you're not just another
                customer – you're part of our extended family. Your desires and
                aspirations for a dream getaway are met with personalized
                attention and dedication. Whether it's curating a tailor-made
                package or arranging the smallest detail, our committed team
                collaborates seamlessly with our global network of experienced
                partners to ensure your journey exceeds expectations. From the
                enchanting landscapes to the vibrant cultures, we offer more
                than just travel – we offer a transformative experience. Each
                adventure becomes a tale woven with unforgettable memories,
                forming the tapestry of your life's most cherished moments. Join
                us on this expedition where every step is carefully charted,
                every destination a new chapter, and every journey an
                opportunity to create lasting impressions. Your voyage isn't
                just a trip; it's an odyssey filled with the promise of
                discovery and the thrill of the unknown."
              </p>
            </div>
          </div>
          <br></br>
          <div className=" ourMission__title">
            <Slide direction="up">
              {" "}
              <h2>Thank You</h2>{" "}
            </Slide>
            <div className="ourVision__Subtitle">
              <p>
                "Your ongoing support and trust mean the world to us. As we pave
                our path to success, we deeply value the confidence you've shown
                in our services. Your unwavering support fuels our commitment to
                delivering exceptional experiences. At the heart of our
                endeavors lies a simple truth: you, our valued customers, are at
                the core of all we do. With every tour, our dedicated team works
                tirelessly to exceed your expectations, ensuring your journey is
                both delightful and conscientious."
              </p>
            </div>
          </div>
        </div>
      </div>

      <br></br>
    </div>
  );
};

export default Aboutus;
