import React from "react";
import { Routes, Route } from "react-router-dom";

import Footer from "./component/Footer.jsx";
import Blog from "./component/Blog/Blog.jsx";
import FAQ from "./component/FaqSection/Faq.jsx";
import Home from "./component/Home/Home.jsx";
import Contactus from "./component/Contactus/Contactus.jsx";
import Navbar from "./component/MyNavbar.jsx";
import Aboutus from "./component/Aboutus/Aboutus.jsx";
function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contactus" element={<Contactus />} />
      </Routes>

      {/* <Blog/> */}

      {/* <Route path="/aboutus" element={<Aboutus />} /> */}
      {/* <FAQ/> */}

      <Footer />
    </div>
  );
}

export default App;
