import React, { useState } from "react";
import "./otherfacilities.css";
import data from "./Devptionaldata.jsx";

import { Slide, Zoom } from "react-awesome-reveal";

const Devotionalfacilities = () => {
  const [noOfElement, setnoOfElement] = useState(3);

  const loadMore = () => {
    setnoOfElement(noOfElement + noOfElement);
  };

  const slice = data.cardData.slice(0, noOfElement);

  return (
    <section>
      <Slide direction="up">
        <div className="Title-Domestic">
          <h1>Temple Specials</h1>
        </div>
      </Slide>

      <div className="row row-cols-1 row-cols-md-3 g-4 International-pakages">
        {slice.map((item, index) => (
          <div className="col" key={index}>
            <div className="card">
              <img src={item.img} alt="" />
              <div className="card-body">
                <h5 className="card-title">{item.title}</h5>
                <p className="card-text">{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="button-showmore">
        <button
          className="btn btn-dark  d-block w-100 show"
          onClick={() => loadMore()}
        >
          Show more
        </button>
      </div>
    </section>
  );
};

export default Devotionalfacilities;
