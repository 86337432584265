import React from "react";
import "./faq.css";
import Accordion from "react-bootstrap/Accordion";
import { Slide, Zoom } from "react-awesome-reveal";

// import img from '../images/25235.jpg'
const Faq = () => {
  return (
    <>
      <div className="Accordion__head">
        <div className="Accordein__subHead">
        <Slide direction="up" >
          <h2>
            {" "}
            Find quick answers to common questions, guiding you through every
            step of your travel journey, within our FAQ
          </h2>
          </Slide>
        </div>
      </div>

      <section className="Accordion__Section">
        {/*------------ firstSection------------ */}
        <Accordion className="Accordion__mainBody" defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="drammu">
            <Accordion.Header>
              <h4>Booking and Services</h4>
            </Accordion.Header>
            <Accordion.Body className="Accordion__body">
              {/* bootstrap */}
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <strong>
                        {" "}
                        How do I book a tour or travel package through your
                        website?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Browse through our website and select your preferred
                      tour or travel package. Click on 'Book Now' and follow the
                      easy steps to confirm your booking.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <strong> What services do you offer?</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - We provide a wide range of services including tours,
                      travel packages, accommodation bookings, transportation
                      options, and more for both domestic and international
                      destinations.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <strong>
                        {" "}
                        Can I customize my travel package or tour itinerary?{" "}
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, absolutely! We offer customizable tour packages to
                      tailor your travel experience according to your
                      preferences.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <strong>
                        {" "}
                        Are there any special discounts or offers available for
                        group bookings?{" "}
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, we provide exclusive discounts and offers for group
                      bookings. Contact our support team for more details.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <strong>
                        {" "}
                        What are the payment options available for booking tours
                        or tickets?{" "}
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - We accept various payment methods including credit/debit
                      cards, online transfers, and other secure payment gateways
                      for your convenience.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSix">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <strong>
                        {" "}
                        Do you provide travel insurance with the bookings?{" "}
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, travel insurance options are available with our
                      bookings for added security and peace of mind during your
                      trip.
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/*-------------- second main --------------*/}
        <Accordion className="Accordion__mainBody" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h4>Destinations and Tours</h4>
            </Accordion.Header>
            <Accordion.Body className="Accordion__body">
              {/* bootstrap */}
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSeven">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="true"
                      aria-controls="collapseSeven"
                    >
                      <strong>
                        What destinations do you cover in your travel packages?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Our travel packages cover a wide array of destinations
                      across the globe, ensuring you have choices to suit your
                      preferences.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingEight">
                    <button
                      class="accordion-button collapsed show"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      <strong>
                        {" "}
                        Can I find information about specific attractions or
                        landmarks in your tour packages?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Absolutely! Our tour packages include detailed
                      information about attractions and landmarks, ensuring you
                      have insights into your travel destinations.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingNine">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      <strong>
                        {" "}
                        Are there guided tours available for certain
                        destinations?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, we offer guided tours for specific destinations,
                      allowing you to explore and learn more about the places
                      you visit.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      <strong>
                        {" "}
                        Do you offer both domestic and international travel
                        packages?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, we offer travel packages for both domestic and
                      international destinations, providing options for all
                      kinds of travelers.
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* Third Section */}
        <Accordion className="Accordion__mainBody" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              <h4>Travel Planning</h4>
            </Accordion.Header>
        
            <Accordion.Body className="Accordion__body">
              {/* bootstrap */}
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingEleven">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="true"
                      aria-controls="collapseEleven"
                    >
                      <strong>
                        {" "}
                        How far in advance should I book my travel plans?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - We recommend booking your travel plans at least a few
                      weeks in advance to secure the best deals and
                      availability. However, last-minute bookings are also
                      possible depending on availability.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwelve">
                    <button
                      class="accordion-button collapse show"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve"
                      aria-expanded="false"
                      aria-controls="collapseTwelve"
                    >
                      <strong>
                        Can I make last-minute bookings for tours or
                        accommodations?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTwelve"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, we accommodate last-minute bookings whenever
                      possible, but availability may be limited.
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThirteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThirteen"
                      aria-expanded="false"
                      aria-controls="collapseThirteen"
                    >
                      <strong>
                        What documents are required for international travel
                        bookings?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseThirteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThirteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - For international travel, ensure you have a valid
                      passport, necessary visas, and any specific travel
                      documentation required by the destination country.
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* 4th-Section */}
        <Accordion className="Accordion__mainBody" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              <h4>Accommodations and Transportation</h4>{" "}
            </Accordion.Header>
            <Accordion.Body className="Accordion__body">
              {/* bootstrap */}
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingfourteen">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefourteen"
                      aria-expanded="true"
                      aria-controls="collapsefourteen"
                    >
                      <strong>
                        Can I choose my preferred type of accommodation during
                        the booking process?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapsefourteen"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, you can select your preferred type of accommodation
                      from our available options during the booking process.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingfifteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefifteen"
                      aria-expanded="false"
                      aria-controls="collapsefifteen"
                    >
                      <strong>
                        *What transportation options are included in the travel
                        packages?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapsefifteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingfifteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Our travel packages usually include a range of
                      transportation options such as flights, trains, buses, or
                      rental car options depending on the package.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSixteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixteen"
                      aria-expanded="false"
                      aria-controls="collapseSixteen"
                    >
                      <strong>
                        {" "}
                        Do you offer airport transfers or transportation to and
                        from attractions?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseSixteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingSixteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, we provide airport transfers and transportation to
                      major attractions as part of our travel packages. Specific
                      details are available for each package.
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* 5th-Section */}
        <Accordion className="Accordion__mainBody" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              <h4>Cancellations and Refunds</h4>{" "}
            </Accordion.Header>
            <Accordion.Body className="Accordion__body">
              {/* bootstrap */}
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOneseven">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneseven"
                      aria-expanded="true"
                      aria-controls="collapseOneseven"
                    >
                      <strong>What is your cancellation policy?</strong>
                    </button>
                  </h2>
                  <div
                    id="collapseOneseven"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOneseven"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Our cancellation policy varies depending on the type of
                      booking. Please refer to our terms and conditions or
                      contact our customer support for specific details.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingeighteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeighteen"
                      aria-expanded="false"
                      aria-controls="collapseeighteen"
                    >
                      <strong>
                        Are there any charges or penalties for canceling or
                        changing bookings?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseeighteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingeighteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Charges or penalties may apply based on the timing of
                      the cancellation or change. Contact our support team for
                      assistance.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingNinteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNinteen"
                      aria-expanded="false"
                      aria-controls="collapseNinteen"
                    >
                      <strong>
                        How can I request a refund for canceled bookings?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseNinteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingNinteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - To request a refund for canceled bookings, please reach
                      out to our customer support team, who will guide you
                      through the process.
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* 6th-Section */}
        <Accordion className="Accordion__mainBody" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              <h4>Customer Support</h4>
            </Accordion.Header>
            <Accordion.Body className="Accordion__body">
              {/* bootstrap */}
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingtwenty">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsetwenty"
                      aria-expanded="true"
                      aria-controls="collapsetwenty"
                    >
                      <strong>
                        How can I contact customer support for assistance or
                        inquiries?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapsetwenty"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingtwenty"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - You can contact our customer support team via phone,
                      email, or live chat available on our website.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwoone">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoone"
                      aria-expanded="false"
                      aria-controls="collapseTwoone"
                    >
                      <strong>
                        Is there 24/7 customer support available for emergency
                        situations during travel?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapseTwoone"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwoone"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Yes, we offer 24/7 customer support for emergency
                      situations during your travel. Contact us anytime for
                      immediate assistance.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingtwoThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsetwoThree"
                      aria-expanded="false"
                      aria-controls="collapsetwoThree"
                    >
                      <strong>
                        Where can I find updates or notifications regarding my
                        bookings or travel plans?
                      </strong>
                    </button>
                  </h2>
                  <div
                    id="collapsetwoThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingtwoThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      - Updates and notifications regarding your bookings or
                      travel plans will be communicated via email, SMS, or
                      through our website's booking portal.
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item> */}
        </Accordion>
      </section>


    </>
  );
};

export default Faq;
