import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import "./App.css";
import Navbar from "../MyNavbar.jsx";
import Footer from "../Footer.jsx";
import Card from "../Card.jsx";
import CountSection from "../countSection";
// import MyCarousel from "..Ncarousal";
import Videocomponent from "../VideoComponent.jsx";
import Aboutus from "../Aboutus/Aboutus.jsx";
import BackgroundSection from "../BackgroundSection.jsx";
// import Faq from "../Faq.jsx";
import Locations from "../ExampleLocations/Locations.jsx";
import OtherFacilities from "../OtherFacilities/OtherFacilities.jsx";
import Devotionalfacilities from "../OtherFacilities/Devotionalfacilities.jsx";
function Homepage() {
  return (
    <div className="Homepage">
     
        <BackgroundSection />

        <Videocomponent />
        <Devotionalfacilities/>
        
        <OtherFacilities/>
   
        <Card />
        
        {/* <MySwiper/> */}
   
        <CountSection />

       
   

    {/* <Locations /> */}
    </div>
  );
}


export default Homepage;
