import React from "react";
import "./Footer.css";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const App = () => {
  return (
    <div className="container">
      <footer className=" fmargin">
        <Slide direction="up" triggerOnce={true}>
          <div className="row justify-content-around">
            <div className="col-6 col-md-2 mb-3">
              {/* <Slide direction="left" triggerOnce={true}> */}

              <ul className="nav flex-column">
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <li className="nav-item mb-2">
                    <a className="nav-link p-0 text-body-secondary">
                      <strong> About Us </strong>
                    </a>
                  </li>
                </Link>

                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    Services
                  </a>
                </li>
                <Link to="faq" style={{ textDecoration: "none" }}>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      FAQ
                    </a>
                  </li>
                </Link>

                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    Blog
                  </a>
                </li>
                <Link to="contactus" style={{ textDecoration: "none" }}>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-body-secondary">
                      Contact us
                    </a>
                  </li>
                </Link>

                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    Privacy Policy
                  </a>
                </li>
              </ul>

              {/* <figure>
                <figcaption style={{ fontSize: "13px" }}>
                  <strong>Download for Users</strong>{" "}
                </figcaption>
                <img
                  src="./images/google-play-badge.png"
                  alt="image for download for users"
                  style={{
                    maxWidth: "150px",
                    marginTop: "-50px",
                  }}
                />
              </figure> */}
              {/* </Slide> */}
            </div>

            <div className="col-6 col-md-2 mb-3">
              {/* <Slide direction="up" triggerOnce={true}> */}
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    <strong>Contact Us </strong>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    Feel free to contact us and reach us !!
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-telephone-outbound"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5" />
                    </svg>
                    {/* <i class="bi bi-telephone-outbound"></i> */}
                    (+91) 8978459588
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&emr=1&ifkv=ASKXGp3q3QWBqzofCfDYXdwogMOtnT6iDwvNYzCLzy6mz00vLG6dXOS7V4UEpTHGleaJhh7HAWO5&ltmpl=default&ltmplcache=2&osid=1&passive=true&rm=false&scc=1&service=mail&ss=1&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S805797456%3A1702907174208723&theme=glif"
                    className="nav-link p-0 text-body-secondary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-envelope-at"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                      <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                    </svg>
                    raziconquer@gmail.com
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="#" className="nav-link p-0 text-body-secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                    10-1-126/G7, Ashok Plaza, Beside Hotel Golconda, Masab Tank,
                    Hyderabad, 500028
                  </a>
                </li>
              </ul>
              {/* <figure >
                  <figcaption style={{ fontSize: '13px' }}> <strong >Download for Drivers</strong> </figcaption>
                  <img
                    src="./images/google-play-badge.png"
                    alt="image for download for users"
                    style={{
                      maxWidth: "150px",
                      marginTop: '-50px'
                    }}
                  />
                </figure> */}
              {/* </Slide> */}
            </div>

            <div className="col-md-5 offset-md-1 mb-3">
              {/* <Slide direction="right" triggerOnce={true}> */}
              <form>
                <p>
                  {" "}
                  <strong>Talk to us</strong>
                </p>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  {/* for name */}

                  <label
                    htmlFor="newsletter1"
                    className="visually-hidden"
                  ></label>
                  <input
                    id="newsletter1"
                    type="text"
                    className="form-control bg-secondary bg-opacity-25"
                    placeholder="Name"
                  />
                  {/* <div className="form-floating mt-3 rounded">
                    <input
                      className="form-control bg-secondary bg-opacity-25"
                      placeholder="Leave a comment here"
                      id="floatingTextinput"
                    ></input>
                    <label htmlFor="floatingTextinput">Name</label>
                  </div> */}
                  {/* for email */}
                  <label
                    htmlFor="newsletter2"
                    className="visually-hidden"
                  ></label>
                  <input
                    id="newsletter2"
                    type="text"
                    className="form-control bg-secondary bg-opacity-25"
                    placeholder="Email"
                  />

                  {/* <div className="form-floating mt-3  rounded">
                    
                    <input
                      id="newsletter2"
                      type="text"
                      className="form-control bg-secondary bg-opacity-25 pl-3"
                      placeholder="Email"
                    />
                    <label
                      htmlFor="newsletter2"
                      // className="visually-hidden"
                    >Email</label>
                  </div> */}
                </div>

                <div className=" mt-3 rounded">
                  <textarea
                    id="newsletter3"
                    className="form-control bg-secondary bg-opacity-25"
                    placeholder="Leave a comment here"
                  ></textarea>
                  <label
                    htmlFor="newsletter3"
                    className="visually-hidden"
                  ></label>
                </div>

                <div className="d-grid gap-2 mt-3 bgcolur rounded">
                  <button type="button" className="btn btn-dark">
                    Submit
                  </button>
                </div>
              </form>
              {/* </Slide> */}
            </div>
          </div>
        </Slide>

        <div className="d-flex flex-column flex-sm-row justify-content-between border-top fmargin">
          <p className=" copyrights">
            © Copyright 2023, All Rights Reserved by{" "}
            <a href="#" className="rftext copyrights">
              {" "}
              Conquer Tours And Travels Private Limited
            </a>
          </p>
          <ul className="list-unstyled d-flex social-medias">
            <li className="ms-3 ">
              <a className="link-body-emphasis" href="https://twitter.com/">
                <Slide direction="left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-twitter"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </Slide>
                {/* <svg className="bi" width="24" height="24">
                  <use xlinkHref="#twitter"></use>
                </svg> */}
              </a>
            </li>

            <li className="ms-3">
              <a
                className="link-body-emphasis"
                href="https://www.instagram.com/"
              >
                <Slide direction="up">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>
                </Slide>
                {/* <svg className="bi" width="24" height="24">
                  <use xlinkHref="#instagram"></use>
                </svg> */}
              </a>
            </li>
            <li className="ms-3">
              <a
                className="link-body-emphasis"
                href="https://www.facebook.com/"
              >
                <Slide direction="right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </Slide>
                {/* <svg className="bi" width="24" height="24">
                  <use xlinkHref="#facebook"></use>
                </svg> */}
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default App;
