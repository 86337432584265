import React, { useState, useEffect, useRef } from "react";
import "./VideoComponent.css";
import { Slide, Zoom } from "react-awesome-reveal";
import travel from "../component/images/travel.jpg";
import travel2 from "../component/images/travel2.jpg";
import video123 from "./images/video123.mp4";
import international from "../component/images/international.jpg";
import Domestic from "../component/images/domesticTour.png";
import accomedation from "../component/images/accomedation.jpg";

function Videocomponent() {
  // ---- Titles code ----
  const titles = [
    "Journey Journals: Discovering Diverse Destinations",
    "Wanderlust Chronicles: Exploring Global Getaways",
    "Destination Diaries: Unveiling Exotic Locations",
    "Adventures Abound: Trekking Through Terrains",
    "Worldly Wonders: Escapades Across Continents",
  ];

  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex((prevIndex) =>
        prevIndex === titles.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  // ---- Titles code  ended ----

  return (
    <div className="container-fluid video1 video-section ">
      <div className="container text-center pt-4 card1">
        <Slide direction="up">
          <div className="myelementSec">
            <h1>"Your Ultimate Travel Companion"</h1>
          </div>
        </Slide>
        {/* <Slide direction="up" triggerOnce={true}> */}
        <div class="row align-items-center subcard1 pt-4">
          <div class="col subcard11">
            <img src={travel} alt="Image Description" class="imgsize" />
            <h5 className="nameContent">Tours</h5>
          </div>
          <div class="col col-lg-4 col-xl-6 subcard12">
            <img src={travel2} alt="Image Description" class="imgsize" />{" "}
            <h5 className="nameContent">Travels</h5>
          </div>
          <div class="col subcard13">
          <img src={accomedation} alt="Image Description" class="imgsize" />
            <h5 className="nameContent">
              Accommodation bookings {/*<br/> Transparency*/}
            </h5>
          </div>
          <div class="col subcard13">
            <img src={Domestic} alt="Image Description" class="imgsize" />
            <h5 className="nameContent">
              {" "}
              Domestic Destinations {/*<br/> Transparency*/}
            </h5>
          </div>
          <div class="col subcard13">
            <img src={international} alt="Image Description" class="imgsize" />
            <h5 className="nameContent">
              International Destinations {/*<br/> Transparency*/}
            </h5>
          </div>
        </div>

        {/* </Slide> */}

        <div className="px-4 py-5 my-5   card2">
          <Slide direction="up">
            <div className="myelementSec">
              <h2 className="subcard2 col-lg-7  mx-auto my-4 display-9 fw-bold text-body-emphasis ">
                <span className="firstTextColor">
                  "Embark on a Journey of Enrichment":
                </span>{" "}
                <span className="secTextColor">
                  -Discover, Savor, and Connect with{" "}
                </span>
                <br></br> Conquer Tours & Travels!
              </h2>
            </div>
          </Slide>
          <div className="col-lg-7  mx-auto my-4 subcard22">
            <Slide direction="up" triggerOnce>
              <p className="lead mb-4  display-9 semicard1">
                Experience cultures that enrich the soul – taste exotic cuisines
                that tantalize your palate, immerse yourself in traditions that
                have withstood centuries, and connect with locals whose warmth
                echoes in your memories.
              </p>
              {/* <p className="semicard2">
                <a href="adsd" className="cardbttn">
                  Start now
                </a>
              </p> */}
            </Slide>
          </div>
        </div>
      </div>
      {/* justify-content-md-center */}

      <div className=" container-fluid card3">
        <div className="row  justify-content-md-center card31 ">
          {/* <div className="col col-lg-4 col-md-11 col-sm-6 subcard3 text-align-left mt-4 "> */}
          <div className="col col-lg-4 col-md-11 col-sm-6 subcard3 text-align-left  ">
            <Slide direction="up">
              <div className="myelementSec">
                <h1>{titles[currentTitleIndex]}</h1>

                <p className="semicard3">
                  "Explore amazing places around the world with our 'Worldly
                  Wonders: Escapades Across Continents.' You'll discover diverse
                  destinations and learn about exotic locations in our Journey
                  Journals, taking you on a thrilling adventure through
                  captivating pages."
                </p>
                {/* <p>
                  <a href="qw" className="cardbttn">
                    Learn More
                  </a>
                </p> */}
              </div>
            </Slide>
          </div>

          <div className="col col-lg-5  col-md-3 col-sm-2 subcard31 ">
            <Zoom triggerOnce={true}>
              <div className="myelementSec">
                <video
                  className="semicard31"
                  src={video123}
                  alt="dff"
                  autoPlay
                  loop
                  muted
                  width="100%"
                  height="100%"
                ></video>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Videocomponent;
