
import "./contactus.css";
import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser'
import emailjs from 'emailjs-com';


const Contactus = () => {


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_pwdw40h', 'template_kaxzn08', form.current, 'flJ9VV6TCOWYPW8oW')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };





  return (


    
    <div className="">
      <section class="contact_us">
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="contact_inner">
                <div class="row">
                  <div class="col-md-10">
                    <div class="contact_form_inner">
                      <div class="contact_field">
                        <h3>Contatc Us</h3>
                        <p>
                          Feel Free to contact us any time. We will get back to
                          you as soon as we can!.
                        </p>

                        <form ref={form} onSubmit={sendEmail}>
                        <input
                          type="text"
                          class="form-control form-group"
                          name="user_name"
                          placeholder="Name"
                        />
                        <input
                          type="email"
                          class="form-control form-group"
                          name="user_email"
                          placeholder="Email"
                        />
                        <textarea
                          class="form-control form-group"
                          name="message"
                          placeholder="Message"
                        ></textarea>
                        <button type="submit" value="Send" class="contact_form_submit">Send</button>
                        {/* <input class="contact_form_submit" type="submit" value="Send" /> */}


                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="right_conatct_social_icon d-flex align-items-end">
                      <div class="socil_item_inner d-flex">
                        <li>
                          <a href="#">
                            <i class="fab fa-facebook-square"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>

             


                <div class="contact_info_sec">
                  <h4>Contact Info</h4>
                  <div class="d-flex info_single align-items-center">
                    <i class="fas fa-headset"></i>
                    <span>(+91) 8978 459588</span>
                  </div>
                  <div class="d-flex info_single align-items-center">
                    <i class="fas fa-envelope-open-text"></i>
                    <span>raziconquer@gmail.com</span>
                  </div>
                  <div class="d-flex info_single align-items-center">
                    <i class="fas fa-map-marked-alt"></i>
                    <span>
                      1000+ Travel partners and 65+ Service city across India,
                      USA, Canada & UAE
                    </span>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="map_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="map_inner">
              <h4>Find Us on Google Map</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quo beatae quasi assumenda, expedita aliquam minima tenetur maiores neque incidunt repellat aut voluptas hic dolorem sequi ab porro, quia error.</p>
                <div className="map_bind">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameborder="0"
                    style={{ border: '0' }} // Corrected: Converted style attribute to JavaScript object
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="map_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="map_inner">
                <h4 className=" Find-us">Find Us on Google Map</h4>
                <p>
                  
                </p>
                <div className="map_bind">


                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.263513532361!2d78.39353047478976!3d17.399137083490128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9696265b8349%3A0x2d63d17ee1b06e74!2sGulshan%20Colony%20Rd%2C%20Friends%20Colony%2C%20Jai%20Hind%20Nagar%20Colony%2C%20Qutub%20Shahi%20Tombs%2C%20Hyderabad%2C%20Telangana%20500008!5e0!3m2!1sen!2sin!4v1702888506091!5m2!1sen!2sin"
                 width="100%"
                 height="450"
                 frameBorder="0"
                 style={{ border: "0" }}
                 allowFullScreen=""
                 aria-hidden="false"
                 tabIndex="0"
               ></iframe>



                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactus;








