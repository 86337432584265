import Tirupati from '../images/Tirupathi.jpg'
import Varanasi from '../images/varanasi.jpg'
import Dargah from '../images/Dargah.jpg'
import Vaishnodev from '../images/Vaishnodev.jpg'
import BodhGaya from '../images/BodhGaya.jpg'
import Tarapith from '../images/Tarapith.jpg'
import Amritsar from '../images/Amritsar.jpg'
import Kedarnath from '../images/kedarnath.jpg'
import Sabarimala from '../images/sabarimala.jpg'
// import Paris from '../images/paris.jpg'





const data = {
    cardData:[
        {
            id:1,
            img:Tirupati,
            title:'TIRUPATI',
            description:'Tirupati is a popular pilgrimage town in south India that is most famous for its legendary temple of Sri Venkateswara Swamy a form of Lord Vishnu. The temple is situated on the top of the seventh peak of the Tirumala hills. The word Tirupati means ‘lord of Lakshmi’. On an average around 60,000 pilgrims visit the temple every day but during the special occasion around 200,000 devotees visit this temple per day',
        },
        {
            id:2,
            img:Varanasi,
            title:'VARANASI, INDIA',
            description:'Varanasi formerly known as Banaras and Kashi – the city of light, has a strong spiritual past.It is believed that those people who die in this holy place directly reach to their salvation. A Hindu pilgrim who takes a dive in the river Ganges gets their sins washed away. Kashi Vishwanath temple is among the most famous temples of Varanasi. It is famous for one of the twelve Jyotirlingas that it houses. As the place holds sheer religious importance, it offers people to experience the spirituality.  The evening ritual of Aarti is the most splendid sight when innumerable mud lamps (diyas) are freed in the river.',
            
        },
        {
            id:3,
            img:Dargah,
            title:'DARGAH SHAREEF, AJMER',
            description:'  Ajmer Sharif Dargah is famous in the world as it is believed that no prayer at this Dargah goes unanswered. The Dargah is finished of marble with a gold gilded roof. The grave of the glorified soul is edged in by silver railing and a marble screen. It is believed that no earnest prayer goes unanswered at this Dargah. The festival of Urs, the death anniversary of the saint, is celebrated with great enthusiasm at the Dargah. It is said that the Mughal Emperor Akbar offered a prayer for an heir at this Dargah in the 16th century and his prayer was answered. ',

        },
        {
            id:4,
            img:Vaishnodev,
            title:'VAISHNODEVI TEMPLE',
            description:'People have immense faith in Ma Vaishno Devi and so it is dream of every Hindu to visit Vaishno Devi temple at least once in a lifetime. The exact location is near Katra town in Reasi district of Jammu and Kashmir.',
        },
        {
            id:5,
            img:BodhGaya,
            title:'BODHGAYA',
            description:'  Bodhgaya is an important pilgrimage site in history of Buddhism. It is believed to be the place of Buddha’s enlightenment attainment. Bodhgaya is in Gaya district of Bihar. The pilgrim site is associated with Mahabodhi temple complex. This religious land is among the famous attractions of North India tourism which is known for Buddhist monuments, temples and monasteries reflecting the historical as well as religious relevance of this place. Bodhi tree is another pious and most significant part of the pilgrimage. This sacred tree witnessed every bit of the process of Buddha’s enlightenment.  ',

        },
        {
            id:6,
            img:Tarapith,
            title:'TARAPITH',
            description:'Tarapith Kolkata by Travel Jaunts Tarapith is a temple town in Birbhum. It is a foremost Hindu pilgrimage epicenter and numerous believers visit the shrine every day. The town of Tarapith is a small, crowded rural town near the Dwarka River in Birbhum district and is also called the city of Tantra Mantra or black magic. Tara, another form of Sati, is worshipped in the temple. The main temple in the town is a small temple built of marble and terra cotta, it has a curved roof called the ‘dochala.',
        },
        {
            id:7,
            img:Amritsar,
            title:'AMRITSAR',
            description:'Gleaming with 750 kg of pure gold, genuine spiritual atmosphere and the holy Amrit Sarovar – the pool of nectar are some of endearing attributes the Golden Temple – the Sikh’s most sacred shrine in Amritsar – is known for. Over the years, it has been visited by multitudes of people of different religious orientations. Also known as Sri Harmandir Saheb, pilgrimage to golden temple is a must for any Sikh or believer of humanity and God. A beautiful mix of Hindu and Muslim styles of architecture here can leave anyone mesmerized.',

            
        },
        {
            id:8,
            img: Kedarnath,
            title:'KEDARNATH',
            description:'Badrinath and Kedarnath are the two celebrated blissful places for devotion. Here you can have the vision of Mother Nature and its splendor. Badrinath is dedicated to lord Vishnu, the main temple was built in the 8th century by the Hindu reformist Adi Sankaracharya. Kedarnath is among the twelve Jyotirling of Lord Siva. Lord Shiva demonstrated in the figure of Jyotirlingam or the intergalactic light. Kedarnath temple has a delicate construction 1000 years old with internal walls ornamented with divinity’s figures and earliest mythical sights, and a big figurine of Nandi Bull outside the entrance.',


        },
        {
            id:9,
            img: Sabarimala,
            title:'SABARIMALA',
            description:'Sabarimala Sree Dharma Sastha Temple is located on a hilltop surrounded by dense forests. East of the central shrine is ‘sannidhanam’is Vavarnada’. This commemorates Vavar, a Muslim associate Lord Ayyappa. It is believed that Lord Ayyappa himself gave specific instructions for the layout of the Ayyappa Temple. According to legends Lord Ayyappa medicated on this sacred place after killing the demon Mahishi. Some legends also say that Parashuram, the creator of Kerala installed the idol of Lord Ayyappa and worshiped him here. As per the strict rules of Sabarimala temple, visitors before embarking to the temple has to lead a life like an ascetic for 41 days.',

        },
        
        
        
        


    ]

}

export default data;