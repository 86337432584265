import React from "react";
import "./MyNavbar.css";
import logo from "./images/Logo-thick.png";
import { Link } from "react-router-dom";

import { Slide } from "react-awesome-reveal";
// import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

function MyNavbar() {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 800,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <header data-bs-theme="light">
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light">
        <div className="container">
          <Slide direction="left" triggerOnce>
            <a className="navbar-brand" href="#">
              <img
                src={logo}
                alt="Logo Ridere"
                style={{ width: "160px", height: "50px", fontWeight: "600" }}
              />
            </a>
          </Slide>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              {/* Rest of your navigation links */}

              <li className="nav-item nav-link active mx-2 link-with-underline">
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  onClick={scrollToTop}
                >
                  <strong className="home-nav">Home</strong>
                </Link>
              </li>

              <Link to="/about" style={{ textDecoration: "none" }}>
                <li className="nav-item nav-link active mx-2 link-with-underline">
                  About
                </li>
              </Link>

              
                <li className="nav-item">
                <Link to="/" style={{ textDecoration: "none" }}>

                  <ScrollLink
                    to="video-section" // Use the same ID as your Videocomponent
                    spy={true}
                    smooth={true}
                    offset={-70} // You might need to adjust this offset
                    duration={100}
                    className="nav-link active mx-2 link-with-underline"
                  >
                    Services
                  </ScrollLink>
                  </Link>

                </li>
              

              <Link to="blog" style={{ textDecoration: "none" }}>
                {" "}
                <li className="nav-item nav-link active mx-2 link-with-underline">
                  Blog
                </li>{" "}
              </Link>

              <Link to="faq" style={{ textDecoration: "none" }}>
                {" "}
                <li className="nav-item nav-link active mx-2 link-with-underline">
                  FAQs
                </li>
              </Link>
            </ul>
            <Link to="contactus">
              {/* <button type="button" > */}
              <a className="btn btn-dark signupBtn">Contact us</a>
            </Link>
            {/* </button> */}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default MyNavbar;
